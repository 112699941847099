import React, { Suspense, lazy } from 'react';
const CameraFeed = lazy(() => import('./Pages/LoginPage'));

export default function puzzle(props) {
  return (
    <Suspense fallback={<div />}>
      <CameraFeed {...props} />
    </Suspense>
  );
}
