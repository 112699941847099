
export default function getGeneralBEC() {
  return {
    settings: {
      title: 'Live Email',
    },
    instructions: [
      {
        text: 'This puzzle is all about phishing, particularly the type known as business email compromise (BEC).',
      },
      {
        text:
          'To pass, you must read the context at the top of your screen, become the attacker in your scenario and craft a phishing email that is most likely to be clicked on by the recipient.',
      },
      {
        text:
          'You must use the clues in the Exam Guide to craft the best phish! You can click on "Context" above the answer options to show/hide the puzzle-specific context at any time. Good luck.',
      },
    ],
    scenarios: {
      one: {
        desc:
          'Impersonate the CEO of Doogle, Tom Richardson, and trick the Doogle PR branch to donate to your spoofed charity. (check the Doogle website to choose the charity to use!)',
        type: 'TEST',
        to: 'PR<Public-Relations@doogle.com>',
        options: [
          [
            {
              id: 101,
              type: 'from',
              text: 'Tom Richardson [t.richardson@d0ogle.com]',
              correct: true,
              selected: false,
            },
            {
              id: 102,
              type: 'from',
              text: 'Tom Richardson [t.richardson@gmail.com]',
              correct: false,
              selected: false,
            },
          ],
          [
            {
              id: 103,
              type: 'subject',
              text: 'Charity Opportunity: Higher Education 4 Kids',
              correct: true,
              selected: false,
            },
            {
              id: 104,
              type: 'subject',
              text: 'We Gotta Give Charity NOW',
              correct: false,
              selected: false,
            },
          ],
          [
            {
              id: 105,
              type: 'attachment',
              text: 'http://charitiez[.]info',
              correct: false,
              selected: false,
            },
            {
              id: 106,
              type: 'attachment',
              text: 'https://highered4kidz.com/donations',
              correct: true,
              selected: false,
            },
          ],
          [
            {
              id: 107,
              type: 'body',
              text:
                "PR Team, It's imperative we give right away. I trust you'll follow my direction and send monies to the charity below.",
              correct: false,
              selected: false,
            },
            {
              id: 108,
              type: 'body',
              text:
                "Hey team, For the past few years, we've been donating to 'Higher Education 4 Kids' and I want to keep the ball rolling. I attached the link to their website and would like to give $15,000 dollars this time around. Please confirm with me once the donation is make. Have a good one!",
              correct: true,
              selected: false,
            },
          ],
        ],
        correctAttempt: false,
        correctTrainingNudge: [
          {
            text:
              'Great job! You sent your first successful phishing email. This is a sub-type of business email compromise (BEC) known as a "charity scam", which typically targets upper level management.',
          },
        ],
        inCorrectTrainingNudge: [
          {
            text:
              'Not quite. Try again. Make sure to use the Exam Guide to help craft the best business email compromise email.',
          },
        ],
      },
      two: {
        desc:
          "You managed to interecept one of 'Coffee Express Roasters' business email to Doogle! Use this information while impersonating the Doogle COO, Janette Ryan, to get the money wired to your bank account.",
        type: 'TEST',
        to: 'Jasmine Noel [j.noel@doogle.com]',
        options: [
          [
            {
              id: 201,
              type: 'from',
              text: 'Janette Ryan [J.Ryan@d0ogle.com]',
              correct: true,
              selected: false,
            },
            {
              id: 202,
              type: 'from',
              text: 'Janette Ryan [jryan3@hotmail.com]',
              correct: false,
              selected: false,
            },
          ],
          [
            {
              id: 203,
              type: 'subject',
              text: 'ACTION Item: Bulk Order',
              correct: false,
              selected: false,
            },
            {
              id: 204,
              type: 'subject',
              text: 'Order #13359382: Doogle',
              correct: true,
              selected: false,
            },
          ],
          [
            {
              id: 205,
              type: 'attachment',
              text: 'Bulk-order.exe',
              correct: false,
              selected: false,
            },
            {
              id: 206,
              type: 'attachment',
              text: 'Invoice13359382.pdf',
              correct: true,
              selected: false,
            },
          ],
          [
            {
              id: 207,
              type: 'body',
              text:
                'Hi Jasmine, It looks like our invoice bounced back from the coffee vendor and they want us to wire the funds to the new account number listed in the attachment. Thanks!',
              correct: true,
              selected: false,
            },
            {
              id: 208,
              type: 'body',
              text:
                'We need double the coffee this month. Order it again but please pay it out to this different bank account. Thank you!',
              correct: false,
              selected: false,
            },
          ],
        ],
        correctAttempt: false,
        correctTrainingNudge: [
          {
            text:
              'Great job! You are getting the hang of this. This is a creative BEC wire-transfer scam, where the victim might transfer the funds without verifying.',
          },
        ],
        inCorrectTrainingNudge: [
          {
            text:
              'Not quite. Try again. Make sure to use the Exam Guide to help craft the best business email compromise email.',
          },
        ],
      },
    },
    conclusion: [
      {
        text: 'Great job! You intuitively learned how an attacker crafts successful phishing emails…',
        buttonText: 'NEXT',
      },
      {
        text: '… and learned a few things to look out for to defend against them :)',
        buttonText: 'NEXT',
      },
      {
        text:
          'You can see that cyber criminals will often target people close to the people they are impersonating, so make sure to double check who an email is REALLY coming from before responding to ANY requests for payment.',
        buttonText: 'FINISH GAME',
      },
    ],
  };
}
