import React, { lazy, Suspense } from 'react';
const Phishing = lazy(() => import('./components/Phishing'));

export default function puzzle(props) {
  return (
    <Suspense fallback={<div />}>
      <Phishing {...props} />
    </Suspense>
  );
}
