import React, { Suspense, lazy } from 'react';

import 'core-js';
import './styles/Hotspot.scss';
const Hotspot = lazy(() => import('./components/Hotspot'));

export default function puzzle(props) {
  return (
    <Suspense fallback={<div />}>
      <Hotspot {...props} />
    </Suspense>
  );
}
