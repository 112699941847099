export const MARK_SELECTED = 'MARK_SELECTED';
export const SCORE_SELECTED = 'SCORE_SELECTED';
export const CLEAR_SELECTED = 'CLEAR_SELECTED';
export const NEXT_ATTEMPT = 'NEXT_ATTEMPT';
export const UPDATE_STEP = 'UPDATE_STEP';
export const SET_GAME_STATE = 'SET_GAME_STATE';
export const SET_STEP = 'SET_STEP';
export const SET_IS_PUZZLE_MODAL_OPEN = 'SET_IS_PUZZLE_MODAL_OPEN';
export const SHOW_MODAL = 'SHOW_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';
