import React, { Suspense, lazy } from 'react';

import 'core-js';
import './utils/i18n';
const ExamGuide = lazy(() => import('./pages/Dashboard'));

export default function puzzle(props) {
  return (
    <Suspense fallback={<div />}>
      <ExamGuide {...props} />
    </Suspense>
  );
}
