export default function getLiveBEC() {
  return {
    settings: {
      title: 'Live Email',
    },
    instructions: [
      {
        text: 'During this game you will be designing and sending phishing emails!',
      },
      {
        text: 'Use the context provided to craft one that is most convincing to your targets...',
      },
      {
        text: 'The first three are test runs, but the last one is live! Good luck!',
      },
    ],
    scenarios: {
      one: {
        desc:
          'Impersonate the CEO of Gizmo Corp. Try to trick Jenny from Finance into sending a wire transfer for $13,000.00.',
        type: 'TEST',
        to: 'Jenny <finance@gizmo.com>',
        options: [
          [
            {
              id: 101,
              type: 'from',
              text: 'CEO <ceo@gizm0.com>',
              correct: true,
              selected: false,
            },
            {
              id: 102,
              type: 'from',
              text: 'CEO<CEO@gizmoooooo.io>',
              correct: false,
              selected: false,
            },
          ],
          [
            {
              id: 103,
              type: 'subject',
              text: 'Urgent -- Late Payments',
              correct: true,
              selected: false,
            },
            {
              id: 104,
              type: 'subject',
              text: "What's up?",
              correct: false,
              selected: false,
            },
          ],
          [
            {
              id: 105,
              type: 'attachment',
              text: 'itunezcardz.exe',
              correct: false,
              selected: false,
            },
            {
              id: 106,
              type: 'attachment',
              text: 'Invoice133498X.pdf',
              correct: true,
              selected: false,
            },
          ],
          [
            {
              id: 107,
              type: 'body',
              text:
                "Hey, Need money … I'm in a pinch. We need to get $13,000 worth of iTunes gift cards ASAP. I'll pay you back once I'm in the office",
              correct: false,
              selected: false,
            },
            {
              id: 108,
              type: 'body',
              text:
                'Hey, I need you to pay the contractors for the work they did on the sidewalks ASAP. Payment information in the attached file. Let me know once this is done!',
              correct: true,
              selected: false,
            },
          ],
        ],
        correctAttempt: false,
        correctTrainingNudge: [
          {
            text:
              'Great Job! You sent your first successful test BEC email. By pretending to be someone trusted, you can make emails that much more believable.',
          },
        ],
        inCorrectTrainingNudge: [
          {
            text:
              'Not quite. Try again. Make sure to use the Exam Guide to help craft the best business email compromise email.',
          },
        ],
      },
      two: {
        desc:
          "Impersonate Jim at Doogle.com to scam one of its vendors, Winter Hardware, into sending money. The contact's name is Gary. Convince him a payment didn't go through and you're ticked!",
        type: 'TEST',
        to: 'Gary <finance@winterware.com>',
        options: [
          [
            {
              id: 201,
              type: 'from',
              text: 'Client relations  <jim@d00gle.com >',
              correct: true,
              selected: false,
            },
            {
              id: 202,
              type: 'from',
              text: 'Maintenance <Jeffy@g00dle.com >',
              correct: false,
              selected: false,
            },
          ],
          [
            {
              id: 203,
              type: 'subject',
              text: 'Urgent -- Payment Rejected',
              correct: true,
              selected: false,
            },
            {
              id: 204,
              type: 'subject',
              text: 'Hey Gary - Hope you are well',
              correct: false,
              selected: false,
            },
          ],
          [
            {
              id: 205,
              type: 'attachment',
              text: 'TransactionDetails.lnk',
              correct: false,
              selected: false,
            },
            {
              id: 206,
              type: 'attachment',
              text: 'TransactionDetails.pdf',
              correct: true,
              selected: false,
            },
          ],
          [
            {
              id: 207,
              type: 'body',
              text:
                'Hey man, Send $100,000 to this P.O. box. It is mandatory. Yes, the price has increased. P.O. Box 2030 Los Angeles, CA.',
              correct: false,
              selected: false,
            },
            {
              id: 208,
              type: 'body',
              text:
                'Hey Gary, Your last payment was rejected because our payment process has changed. You need to cut a check for $17,560.00 and send to the following P.O. box today in order to continue using our services. P.O. Box 2030 Los Angeles, CA',
              correct: true,
              selected: false,
            },
          ],
        ],
        correctAttempt: false,
        correctTrainingNudge: [
          {
            text:
              'Good work! Impersonating well known service providers can increase the chances of a successful phish because the client trusts them! So always make sure to double check the sender.',
          },
        ],
        inCorrectTrainingNudge: [
          {
            text:
              'Not quite. Try again. Make sure to use the Exam Guide to help craft the best business email compromise email.',
          },
        ],
      },
      three: {
        desc:
          "Impersonate the CEO's executive assistant, Jed, at Real Real-Estate and convince the bookkeeper to put $75,000 down on a new 'property.'",
        type: 'TEST',
        to: '<bookkeeping@invest.com>',
        options: [
          [
            {
              id: 301,
              type: 'from',
              text: 'jed <jed@rrealrealestate.com>',
              correct: true,
              selected: false,
            },
            {
              id: 302,
              type: 'from',
              text: 'Jed <Jed@gmail.com>',
              correct: false,
              selected: false,
            },
          ],
          [
            {
              id: 303,
              type: 'subject',
              text: 'Need to Close Deal -ASAP',
              correct: true,
              selected: false,
            },
            {
              id: 304,
              type: 'subject',
              text: 'Deal closure later this month',
              correct: false,
              selected: false,
            },
          ],
          [
            {
              id: 305,
              type: 'attachment',
              text: 'http://propertyfinderz.com/home/144002744',
              correct: false,
              selected: false,
            },
            {
              id: 306,
              type: 'attachment',
              text: 'https://www.propertyfinderz.com/home/144002744',
              correct: true,
              selected: false,
            },
          ],
          [
            {
              id: 307,
              type: 'body',
              text:
                'Hey, I am Dave’s new assistant. I am looking to get on payroll so I can receive my first paycheck. It is about a week late. The following attachment has the needed information.',
              correct: false,
              selected: false,
            },
            {
              id: 308,
              type: 'body',
              text:
                'Hey, It’s Jed and as per Dave’s request, I need to close a deal today while he is on vacation. I need you to wire me $75,000 ASAP so that I can put the down payment on the following property by the EOD. /n All the information needed is in the following link.',
              correct: true,
              selected: false,
            },
          ],
        ],
        correctAttempt: false,
        correctTrainingNudge: [
          {
            text: 'Great work! A sense of urgency in an email can be a tip that it might be phishing!',
          },
        ],
        inCorrectTrainingNudge: [
          {
            text:
              'Not quite. Try again. Make sure to use the Exam Guide to help craft the best business email compromise email.',
          },
        ],
      },
      four: {
        desc:
          "Impersonate reporter Brian Jordan from the Business Brief. Your goal is to get (CEO) Phillip Owens' executive assistant, Melanie, to answer security questions without knowing it. Casually gather info about where Phillip was born, his first job and the name of his first pet.",
        type: 'LIVE',
        to: 'Melanie <m.judkins@nexstar.cap>',
        options: [
          [
            {
              id: 401,
              type: 'from',
              text: 'Brian <b.jordan@BB.com>',
              correct: true,
              selected: false,
            },
            {
              id: 402,
              type: 'from',
              text: 'brian <b.jordan@yahoo.com>',
              correct: false,
              selected: false,
            },
          ],
          [
            {
              id: 403,
              type: 'subject',
              text: 'Op-ed Inquiry -- Phillip Owens',
              correct: true,
              selected: false,
            },
            {
              id: 404,
              type: 'subject',
              text: 'Questions about Owens',
              correct: false,
              selected: false,
            },
          ],
          [
            {
              id: 405,
              type: 'attachment',
              text: 'op-ed_Owens_bio.bat',
              correct: false,
              selected: false,
            },
            {
              id: 406,
              type: 'attachment',
              text: 'op-ed_Owens_bio.pdf',
              correct: true,
              selected: false,
            },
          ],
          [
            {
              id: 407,
              type: 'body',
              text:
                "Hey, I'm a reporter. I need info on your boss. What city was Phillip born in? What was his first job? What was his first pet's name? What was his mother's maiden name? Hurry! This is important.",
              correct: false,
              selected: false,
            },
            {
              id: 408,
              type: 'body',
              text:
                "Hi Melanie, I am a reporter from the Business Brief doing an op-ed on Phillip Owens. I was able to gather most of the info I need from his speaking engagements, but I was hoping to get a few more details about his bio before we publish. If you could fill out the attached form and respond back that'd be great!",
              correct: true,
              selected: false,
            },
          ],
        ],
        correctAttempt: false,
        correctTrainingNudge: [
          {
            text:
              'Melanie filled out the form and responded with the following answers! "He was born in Pittsburgh PA, and his first job was a busboy at Generios if you can believe it! Per your attachment, his first dog was called Ruffles :)"',
          },
          {
            text: "And just like that, you've successfully compromised information on a CEO!",
          },
        ],
        inCorrectTrainingNudge: [
          {
            text:
              'Not quite. Try again. Make sure to use the Exam Guide to help craft the best business email compromise email.',
          },
        ],
      },
    },
    conclusion: [
      {
        text: 'You intuitively learned how an attacker crafts successful phishing emails…',
        buttonText: 'NEXT',
      },
      {
        text: '… and learned a few things to look out for to defend against them :)',
        buttonText: 'NEXT',
      },
      {
        text:
          'You can see that cyber criminals will often target people close to the people they are impersonating, so make sure to double check who an email is REALLY coming from before responding to ANY requests for payment.',
        buttonText: 'FINISH GAME',
      },
    ],
  };
}
