import React, { Suspense, lazy } from 'react';
const Unscramble = lazy(() => import('./components/Unscramble'));

export default function puzzle(props) {
  return (
    <Suspense fallback={<div />}>
      <Unscramble {...props} />
    </Suspense>
  );
}
