export const wordify = (attemptNum) => {
  switch (attemptNum) {
    case 1:
      return 'one';
    case 2:
      return 'two';
    case 3:
      return 'three';
    case 4:
      return 'four';
    case 5:
      return 'five';
    case 6:
      return 'six';
    default:
      return 0;
  }
};

export const setScenario = (attemptNum, scenarios) => {
  switch (attemptNum) {
    case 1:
      return scenarios.one;
    case 2:
      return scenarios.two;
    case 3:
      return scenarios.three;
    case 4:
      return scenarios.four;
    case 5:
      return scenarios.five;
    case 6:
      return scenarios.six;

    default:
      return 0;
  }
};

export const styleSelection = (els) => {
  els.forEach((sel) => {
    if (sel.textContent !== '') {
      sel.classList.add('selected-answer');
    }
  });
};
