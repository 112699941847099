import getLiveBEC from './LiveBec';
import getGeneralBEC from './GeneralBec';

export default function setPuzzleContent(puzzleVariation) {
  switch (puzzleVariation) {
    case 'trainingGrounds': {
      return getLiveBEC();
    }
    case 'entranceExam': {
      return getGeneralBEC();
    }
    default:
      return getLiveBEC();
  }
}
