import React, { createContext, useReducer, useEffect } from 'react';
import AppReducer from './AppReducer';
import {
  MARK_SELECTED,
  SCORE_SELECTED,
  NEXT_ATTEMPT,
  CLEAR_SELECTED,
  SHOW_MODAL,
  CLOSE_MODAL,
  UPDATE_STEP,
  SET_STEP,
  SET_GAME_STATE,
} from './types';
import { setScenario } from '../utils/utils';
import setPuzzleContent from '../data';
import size from 'lodash/size';
import { INTRO, CONCLUSION } from '../constants';

let initialState = {
  attemptNum: 1,
  step: 0,
  gameState: INTRO,
  isPuzzleModalOpen: true,
  selected: [],
  correctAttempt: false,
  gameFinished: false,
};

// Create context
export const GlobalContext = createContext(initialState);

// Provider Component
export const GlobalProvider = ({
  children,
  puzzleVariation,
  isLeader,
  updateState,
  puzzleState,
  callback,
  puzzleContent,
}) => {
  const scenarioCount = size(puzzleContent?.puzzleDefinition?.scenarios) || 2;
  // Set puzzle data by prop or path url

  if (size(puzzleContent?.puzzleDefinition)) {
    const { intro, puzzleDefinition, conclusion, settings } = puzzleContent;
    initialState = { ...initialState, ...intro, settings, ...puzzleDefinition, ...conclusion };
  } else {
    if (puzzleVariation === 'entranceExam') {
      initialState = { ...initialState, ...setPuzzleContent('entranceExam') };
    } else {
      initialState = { ...initialState, ...setPuzzleContent('trainingGrounds') };
    }
  }

  useEffect(() => {
    if (isLeader) {
      syncState(initialState);
    }
  }, []);

  function syncState(newState, stateCallback) {
    const prevState = size(puzzleState) ? puzzleState : newState;
    const mergedState = { ...prevState, ...newState };

    // NOTE: Firebase throws errors if you try to set undefined as a value
    Object.keys(mergedState).forEach((key) => {
      if (mergedState[key] === undefined) {
        delete mergedState[key];
      }
    });

    if (isLeader) {
      updateState({ puzzleState: mergedState });
    }
    setTimeout(() => {
      stateCallback && stateCallback(mergedState);
    }, 300);
  }

  const [state, dispatch] = useReducer(AppReducer, initialState);

  // Actions
  function markSelected(option) {
    option.selected = true;

    if (isLeader) {
      dispatch({
        type: MARK_SELECTED,
        payload: option,
        syncState,
      });
    }
  }

  function scoreSelected() {
    if (isLeader) {
      const scenario = setScenario(state.attemptNum, state.scenarios);

      const selectedCorrect = Object.values(state.selected).map((item) => {
        return item.correct;
      });
      selectedCorrect.includes(false) || selectedCorrect.length < scenario?.options?.length
        ? (state.correctAttempt = false)
        : (state.correctAttempt = true);

      if (state.correctAttempt === true) {
        launchModal(scenario.correctTrainingNudge, puzzleState.settings?.strings?.correct || 'Correct');
        nextAttempt();
        clearSelected();
      } else {
        // Show Modal w/Incorrect Training Nudge
        launchModal(scenario.inCorrectTrainingNudge, puzzleState.settings?.strings?.incorrect || 'Incorrect');
        clearSelected();
      }

      dispatch({
        type: SCORE_SELECTED,
        payload: state.correctAttempt,
        syncState,
      });
    }
  }

  function nextAttempt() {
    state.attemptNum++;

    if (isLeader) {
      dispatch({
        type: NEXT_ATTEMPT,
        payload: state.attemptNum,
        syncState,
      });
    }
  }

  function setGameState(gameState, step, isPuzzleModalOpen) {
    if (isLeader) {
      dispatch({
        type: SET_GAME_STATE,
        payload: {
          gameState,
          step,
          isPuzzleModalOpen,
        },
        syncState,
      });
    }
  }

  function clearSelected() {
    if (isLeader) {
      const selections = document.querySelectorAll('.selection');

      selections.forEach((selection) => {
        selection.textContent = '';
        selection.classList.remove('selected-answer');
      });

      dispatch({
        type: CLEAR_SELECTED,
        syncState,
      });
    }
  }

  function setStep(step) {
    state.step = step;

    if (isLeader) {
      dispatch({
        type: SET_STEP,
        payload: state.step,
        syncState,
      });
    }
  }

  function launchModal(modalText, modalTitle) {
    if (isLeader) {
      dispatch({
        type: SHOW_MODAL,
        payload: {
          modalText,
          modalTitle,
        },
        syncState,
      });
    }
  }

  function closeModal() {
    if (isLeader) {
      dispatch({
        type: CLOSE_MODAL,
        syncState,
      });
    }
  }

  function updateStep(step) {
    setStep(step);

    if (isLeader) {
      dispatch({
        type: UPDATE_STEP,
        payload: state.step,
        syncState,
      });
    }
  }

  return (
    <GlobalContext.Provider
      value={{
        puzzleVariation: puzzleState.puzzleVariation,
        settings: puzzleState.settings,
        instructions: puzzleState.instructions,
        attemptNum: puzzleState.attemptNum,
        scenarios: puzzleState.scenarios,
        selected: puzzleState.selected || [],
        correctAttempt: puzzleState.correctAttempt,
        conclusion: puzzleState.conclusion,
        passCode: puzzleState.passCode,
        showModal: puzzleState.showModal,
        gameState: puzzleState.gameState,
        step: puzzleState.step,
        gameFinished: puzzleState.gameFinished,
        modalTitle: puzzleState.modalTitle,
        modalText: puzzleState.modalText,
        state: puzzleState,
        finalCallback: callback,
        isLeader: isLeader,
        scenarioCount,
        markSelected,
        scoreSelected,
        nextAttempt,
        clearSelected,
        setStep,
        launchModal,
        closeModal,
        updateStep,
        setGameState,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
