import React, { Suspense, lazy } from 'react';

const Corkboard = lazy(() => import('./components/Corkboard'));

export default function puzzle(props) {
  return (
    <Suspense fallback={<div />}>
      <Corkboard {...props} />
    </Suspense>
  );
}
