import React, { Suspense, lazy } from 'react';
import { GlobalProvider } from './context/GlobalState';

const EmailBuilder = lazy(() => import('./components/EmailBuilder'));

export default function puzzle(props) {
  return (
    <Suspense fallback={<div />}>
      <GlobalProvider {...props}>
        <EmailBuilder {...props} />
      </GlobalProvider>
    </Suspense>
  );
}
