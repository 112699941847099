import React, { Suspense, lazy } from 'react';

import 'custom-event-polyfill';
const Vishing = lazy(() => import('./components/Vishing'));

export default function puzzle(props) {
  return (
    <Suspense fallback={<div />}>
      <Vishing {...props} />
    </Suspense>
  );
}
