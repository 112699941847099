import React, { Suspense, lazy } from 'react';
const DatingGame = lazy(() => import('./components/DatingGame'));

export default function puzzle(props) {
  return (
    <Suspense fallback={<div />}>
      <DatingGame {...props} />
    </Suspense>
  );
}
