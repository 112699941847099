import React, { Suspense, lazy } from 'react';

import 'core-js';
import './utils/i18n';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const EvidenceLocker = lazy(() => import('./pages/Dashboard'));

export default function puzzle(props) {
  return (
    <Suspense fallback={<div />}>
      <EvidenceLocker {...props} />
    </Suspense>
  );
}
