import React, { Suspense, lazy } from 'react';

import './styles/ImageComplete.scss';
const ImageComplete = lazy(() => import('./components/ImageComplete'));

export default function puzzle(props) {
  return (
    <Suspense fallback={<div />}>
      <ImageComplete {...props} />
    </Suspense>
  );
}
