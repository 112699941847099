import React, { Suspense, lazy } from 'react';
const ReOrder = lazy(() => import('./components/ReOrder'));

export default function puzzle(props) {
  return (
    <Suspense fallback={<div />}>
      <ReOrder {...props} />
    </Suspense>
  );
}
